import React, { useState } from "react";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";

function Contact() {
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  return (
    <div className="contact-container">
      <p>
        JioMatrimony is excited to assist you in finding a mate as soon as
        possible. We will be happy to help you with any questions you may have
        at any time. We offer the following methods for you to get in touch with
        our customer support team.
      </p>
      <div className="contact-ways">
        <p>Helpline Number and Email</p>
        <div className="country-name">
          <img src={require("../../images/flag.png")} alt="" />
          <p>INDIA <span>91-7893206880</span></p>
        </div>

        <div className="country-name">
          <img src={require("../../images/email.webp")} alt="" />
          <p><span>contact@jiomatrimony.com</span></p>
        </div>
        <div style={{ borderBottom: "dotted 1px grey" }}></div>
        <p id="faq" >Frequently Asked Questions</p>
        <div className="each-item">
          <div className="each-card">
            <div className="each-question">
              <p>
                <span>1.</span> How can I find my match quickly.
              </p>
              {show1 ? (
                <SlArrowUp
                  onClick={() => setShow1(!show1)}
                  className="icon-toshow"
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <SlArrowDown
                  onClick={() => setShow1(!show1)}
              
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
            {show1 && (
              <div className="each-aws">
                <ul>
                  <li>
                    Login Regularly to see the new matches and suggestions.
                    <a href="/signin" target="_blank">
                      Login now
                    </a>
                    ,
                    <li>
                      Have a complete profile update with your current career
                      details, complete education and profile picture / video
                      <a href="my-profile" target="_blank">
                        Update your profile now
                      </a>
                      .
                    </li>
                    <li>
                      Be a premium member, so to stay on top of search results
                      for other members looking for you.
                      <a href="/subscribe-plan" target="_blank">
                        Know more about the Premium plans here
                      </a>
                    </li>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="each-card">
            <div className="each-question">
              <p>
                <span>2.</span> How fast can I find my match ?
              </p>
              {show2 ? (
                <SlArrowUp
                  onClick={() => setShow2(!show2)}
                  className="icon-toshow"
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <SlArrowDown
                  onClick={() => setShow2(!show2)}
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
            {show2 && (
              <div className="each-aws">
                <p>
                  We hope that you will find your perfect partner at the
                  earliest. Good things shouldn't wait, shouldn't it? But since
                  we understand that we are not looking for any person, but your
                  Perfect life partner for the most important and the longest
                  journey in your life, we shouldn't just hurry and pick
                  someone, right?. So let's take our time, try to understand
                  your matches better, and then make the best decision. However,
                  you could feel a bit confused or lost in the search; if so,
                  you can get the support of a designated relationship manager
                  by choosing a VIP package, you get a real person who will be
                  there as your friend to help you with the search and
                  shortlisting.
                </p>
              </div>
            )}
          </div>
          <div className="each-card">
            <div className="each-question">
              <p>
                <span>3.</span> How can I create an account on Jiomatrimony.com
                ?
              </p>
              {show3 ? (
                <SlArrowUp
                  onClick={() => setShow3(!show3)}
                  className="icon-toshow"
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <SlArrowDown
                  onClick={() => setShow3(!show3)}
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
            {show3 && (
              <div className="each-aws">
                <p>
                  It's simple. <a href="/signup">Sign up Now</a> to create your
                  profile now. Enter your profile details (the more information,
                  the better) and authenticate your profile with your mail ID
                  and phone number. That's it. Further, you would receive a
                  verification call from our JioMatrimony agent who would ask
                  you some verification questions, and after that your account
                  is active. You can send interest to any other profile only
                  once your verification is complete
                </p>
              </div>
            )}
          </div>

          <div className="each-card">
            <div className="each-question">
              <p>
                <span>4.</span> How can I search for suitable profiles ?
              </p>
              {show4 ? (
                <SlArrowUp
                  onClick={() => setShow4(!show4)}
                  className="icon-toshow"
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <SlArrowDown
                  onClick={() => setShow4(!show4)}
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
            {show4 && (
              <div className="each-aws">
                <p>
                  It's simple.{" "}
                  <a href="/profileid">Search by JIOID which is unique </a>
                  There are three options - Normal filter, advanced filters, ID
                  search, and Keyword search. In a normal filter, you can search
                  matches based on some basic parameters like country,
                  nationality etc. However in advanced filter option, you can
                  narrow down your search results based on their profession,
                  education etc. In ID search you can enter a particular JIOID
                  and search.You can go for an advanced search by setting your
                  specific preferences too, if you are a premium plan member.
                </p>
              </div>
            )}
          </div>
          <div className="each-card">
            <div className="each-question">
              <p>
                <span>5.</span> Who should get a JioMatrimony Assist?
              </p>
              {show5 ? (
                <SlArrowUp
                  onClick={() => setShow5(!show5)}
                  className="icon-toshow"
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <SlArrowDown
                  onClick={() => setShow5(!show5)}
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
            {show5 && (
              <div className="each-aws">
                <p>
                  You should get a JioMatrimony Assist plan if you fall in one
                  or more of the below categories.
                  <ul>
                    <li>
                      You like to speed up the journey and want to find your
                      partner very soon.
                    </li>
                    <li>
                      You have a hectic life and don’t have time to browse
                      through the new matches joining the site every day.
                    </li>
                    <li>
                      You have entrusted the critical duty of your partner
                      search to your dear parents, who might not be technically
                      savvy enough to search and browse the new matches.
                    </li>
                  </ul>
                </p>
              </div>
            )}
          </div>
          <div className="each-card">
            <div className="each-question">
              <p>
                <span>6.</span> Can I log into my profile from multiple devices
                simultaneously?
              </p>
              {show6 ? (
                <SlArrowUp
                  onClick={() => setShow6(!show6)}
                  className="icon-toshow"
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <SlArrowDown
                  onClick={() => setShow6(!show6)}
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
            {show6 && (
              <div className="each-aws">
                <p>
                  Yes, you can log into your profile from multiple devices
                  simultaneously.
                </p>
              </div>
            )}
          </div>
          <div className="each-card">
            <div className="each-question">
              <p>
                <span>7.</span> How do I log in if I forget my password?
              </p>
              {show7 ? (
                <SlArrowUp
                  onClick={() => setShow7(!show7)}
                  className="icon-toshow"
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <SlArrowDown
                  onClick={() => setShow7(!show7)}
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
            {show7 && (
              <div className="each-aws">
                <p>
                  If you forgot your login password or want to change it, follow
                  these steps:
                  <ul>
                    <li>
                      Click on <a href="/forgot-password"> Forgot Password.</a>
                    </li>
                    <li>Enter your email ID associated with your account.</li>
                    <li>
                      You will receive a SMS & email ID with a link to reset
                      your password.
                    </li>
                    <li>Follow the link to reset your password securely.</li>
                  </ul>
                </p>
              </div>
            )}
          </div>
          <div className="each-card">
            <div className="each-question">
              <p>
                <span>8.</span> Can I change the Details associated with my
                matrimony account?
              </p>
              {show8 ? (
                <SlArrowUp
                  onClick={() => setShow8(!show8)}
                  className="icon-toshow"
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <SlArrowDown
                  onClick={() => setShow8(!show8)}
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
            {show8 && (
              <div className="each-aws">
                <p>
                  Yes, you can change your Personal details that you Registered.
                  <ul>
                    <li>
                      Go to the
                      <a href="/my-profile"> My Profile </a> section
                    </li>
                    <li>Enter your email ID associated with your account.</li>
                    <li>
                      You will receive a SMS & email ID with a link to reset
                      your password.
                    </li>
                    <li>Follow the link to reset your password securely.</li>
                  </ul>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
