import React, { useEffect, useState } from "react";
import axios from "axios";
import CookieService from "encrypted-cookie";
import { useParams } from "react-router-dom";
import "../../styles/UserDetails.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import defaultAvatar from "../../images/OIP.jpeg";

const UserDetail = () => {
  const { jioId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showVal, setShowVal] = useState(false);
  const [errorContact, setErrorContact] = useState(null);

  // Remove AutoplaySlider if not needed
  // const AutoplaySlider = withAutoplay(AwesomeSlider);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const handleReveal = () => {
    const personalDataString = localStorage.getItem("personal");
    const personalData = JSON.parse(personalDataString);
    console.log(personalData.JIOID);

    axios
      .get(`/api/users/CheckContactLimit`, {
        params: { id: personalData.JIOID },
      })
      .then((response) => {
        if (response.data.message === "Contact Revealed") {
          setShowVal(true);
        }
      })
      .catch((error) => {
        setErrorContact(error.response.data.error);
        console.error("Error fetching users data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = CookieService.getCookie("token");
      const custom_headers = {
        Authorization: token,
      };
      try {
        const response = await axios.get(
          `/api/users/GetUsersById?id=${jioId}`,
          {
            headers: custom_headers,
          }
        );

        console.log("Response from API:", response.data);
        setUserDetails(response.data.users[0]);

        setImages(response.data.users[0].images);
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (jioId) {
      fetchUserDetails();
    }
  }, [jioId]);

  if (loading) return <div>Loading...</div>;

  return (
    <div className="user-detail">
      <div className="userImage">
        {images && images.length > 0 ? (
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} className="images">
                {image ? (
                  <img
                    src={`data:image/jpeg;base64,${image}`}
                    alt={`User Image ${index + 1}`}
                  />
                ) : (
                  <img
                    src={defaultAvatar}
                    alt="Default Avatar"
                    className="default-avatar"
                  />
                )}
              </div>
            ))}
          </Slider>
        ) : (
          <img
            src={defaultAvatar}
            alt="Default Avatar"
            className="default-avatar"
          />
        )}
      </div>

      {/* User details section */}
      <div className="userDetails">
        <div className="name">
          <h2>{userDetails.Name}</h2>
        </div>
        <div className="aboutme">
          <p><strong>JIOID:</strong> {userDetails.JIOID}</p>
          <p>
            <strong>About Me:</strong> {userDetails.AboutMe || "Not Specified"}
          </p>
        </div>
        <div className="basic-information">
          <p>
            <strong>Age:</strong> {userDetails.Age || "Not Specified"}
          </p>
          <p>
            <strong>Height:</strong> {userDetails.height || "Not Specified"}
          </p>
          <p>
            <strong>Gender:</strong> {userDetails.gender || "Not Specified"}
          </p>
          <p>
            <strong>Education:</strong>{" "}
            {userDetails.Education || "Not Specified"}
          </p>
          <p>
            <strong>Profession:</strong>
            {userDetails.Profession || "Not Specified"}
          </p>
          <p>
            <strong>Salary (PA):</strong>{" "}
            {userDetails["Salary-PA_Standardized"] || "Not Specified"}
          </p>
          <p>
            <strong>Date of Birth:</strong>{" "}
            {new Date(userDetails.DateOfBirth).toLocaleDateString()}
          </p>
          <p>
            <strong>Marital Status:</strong>{" "}
            {userDetails.MaritalStatus || "Not Specified"}
          </p>
        </div>
        <div className="family">
          <p>
            <strong>About Family:</strong>{" "}
            {userDetails.AboutFamily || "Not Specified"}
          </p>
          <p>
            <strong>Preferred Match:</strong>{" "}
            {userDetails.Prefering || "Not Specified"}
          </p>
        </div>
        <div className="adress">
          <p>
            <strong>Address:</strong> {userDetails.Address || "Not Specified"}
          </p>
          <p>
            <strong>City:</strong> {userDetails.City || "Not Specified"}
          </p>
          <p>
            <strong>Country:</strong> {userDetails.Country || "Not Specified"}
          </p>
          <p>
            <strong>Cast:</strong> {userDetails.Cast || "Not Specified"}
          </p>
        </div>
        <div className="conatctinfo">
          {!showVal ? (
            <span
              onClick={handleReveal}
              className="line-shade"
              style={{ width: "fit-content" }}
            >
              Reveal Contact
            </span>
          ) : (
            <>
              <p>Mobile Number: {userDetails.Mobile || "Not Specified"}</p>
              <p>Email: {userDetails.email || "Not Specified"}</p>
            </>
          )}
          {errorContact && <p className="error-message">{errorContact}</p>}
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
