// import React, { useState } from "react";
// import "../../styles/profileid.css";
// import CookieService from "encrypted-cookie";
// import axios from "axios";

// const ProfileId = () => {
//   const [searchValue, setSearchValue] = useState("");
//   const [searchType, setSearchType] = useState("JIOID");
//   const [data, setData] = useState([]);
//   const [error, setError] = useState(null);
//   const [errorContact, setErrorContact] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [showVal, setShowVal] = useState({});
//   const [count, setCount] = useState("");
//   const [mydetails, setMydetails] = useState("");

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (!searchValue) {
//       setError("Please enter a valid search value.");
//       return;
//     }

//     setLoading(true);
//     setError(null);

//     const custom_headers = {
//       "Content-Type": "application/json",
//       Authorization: CookieService.getCookie("token"),
//     };

//     const searchParam =
//       searchType === "JIOID" ? { id: searchValue } : { Name: searchValue };

//     axios
//       .get(`/api/users/GetUsersById`, {
//         params: searchParam,
//         headers: custom_headers,
//       })
//       .then((response) => {
//         setData(response.data.users);
//         console.log("Fetched users:", response.data.users);
//       })
//       .catch((error) => {
//         setError("Error fetching users data. Please try again.");
//         console.error("Error fetching users data:", error);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };

//   const handleChange = (e) => {
//     setSearchValue(e.target.value);
//   };

//   const handleSearchTypeChange = (e) => {
//     setSearchType(e.target.value);
//   };

//   const handleReveal = (userId) => {
//     const personalDataString = localStorage.getItem("personal");
//     const personalData = JSON.parse(personalDataString);
//     setCount("");
//     setMydetails(personalData);

//     axios
//       .get(`/api/users/CheckContactLimit`, {
//         params: { id: personalData.JIOID },
//       })
//       .then((response) => {
//         if (response.data.message === "Contact Revealed") {
//           setCount(response.data.limitcount);
//           setShowVal((prev) => ({ ...prev, [userId]: true }));
//         }
//       })
//       .catch((error) => {
//         setErrorContact(error.response.data.error);
//         console.error("Error fetching users data:", error);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };

//   return (
//     <div className="bottom-section">
//       <form onSubmit={handleSubmit}>
//         <div className="radios">
//           <label>
//             <input
//               type="radio"
//               name="searchType"
//               value="JIOID"
//               checked={searchType === "JIOID"}
//               onChange={handleSearchTypeChange}
//             />
//             Search by JIOID
//           </label>
//           <label>
//             <input
//               type="radio"
//               name="searchType"
//               value="Name"
//               checked={searchType === "Name"}
//               onChange={handleSearchTypeChange}
//             />
//             Search by Name
//           </label>
//         </div>
//         <div className="searchSubmit">
//           <input
//             type="text"
//             placeholder={`Enter ${
//               searchType === "JIOID" ? "JIOID" : "Name"
//             } here...`}
//             value={searchValue}
//             onChange={handleChange}
//           />
//           <button className="line-shade" type="submit" disabled={loading}>
//             {loading ? "Loading..." : "Submit"}
//           </button>
//         </div>
//       </form>

//       {error && <p className="error-message">{error}</p>}

//       <div className="profiles-container">
//         {data.length > 0 &&
//           data.map((each, index) => (
//             <div key={index} className="profile-user">
//               {each.images && each.images.length > 0 ? (
//                 <img
//                   className="profile-img"
//                   src={`data:image/jpeg;base64,${each.images[0]}`}
//                   alt={`Profile image for ${each.Name}`}
//                 />
//               ) : (
//                 <img src={require("../../images/OIP.jpeg")} alt="default" />
//               )}
//               <div className="profile-info">
//                 <h2>{each.Name}</h2>
//                 <p>Age: {each.Age}</p>
//                 <p>Denomination: {each.Denomination}</p>
//                 <p>Bride or Groom: {each["Bride/Bridegroom"]}</p>
//                 <p>Cast: {each.Cast}</p>
//                 <p>Marital Status: {each.MaritalStatus}</p>
//                 <p>City: {each.City}</p>
//                 <p>Salary: {each["Salary-PA"] || "Not-Specified"}</p>

//                 <div className="contactinfo">
//                   {/* {count && (
//                     <p>
//                       You have viewed {mydetails.contactShowLimit} contacts. You
//                       can still view
//                       {mydetails.membership_status === "Paid"
//                         ? 50 - mydetails.contactShowLimit
//                         : mydetails.membership_status === "Unpaid"
//                         ? 10 - mydetails.contactShowLimit
//                         : mydetails.membership_status === "Expired"
//                         ? 5 - mydetails.contactShowLimit
//                         : 0}{" "}
//                       more contacts before reaching your limit.
//                     </p>
//                   )} */}

//                   {!showVal[each.JIOID] ? (
//                     <>
//                       <span
//                         onClick={() => handleReveal(each.JIOID)}
//                         className="line-shade"
//                         style={{ width: "fit-content" }}
//                       >
//                         Reveal Contact
//                       </span>
//                       {errorContact && (
//                         <p className="error-message">{errorContact}</p>
//                       )}
//                     </>
//                   ) : (
//                     <>
//                       {count && (
//                         <p>
//                           You can view {count} more contacts before reaching
//                           your limit.
//                         </p>
//                       )}
//                       <p>Mobile Number: {each.Mobile}</p>
//                       <p>Email: {each.email}</p>
//                     </>
//                   )}
//                 </div>
//               </div>
//             </div>
//           ))}
//       </div>
//     </div>
//   );
// };

// export default ProfileId;

import React, { useState } from "react";
import "../../styles/profileid.css";
import CookieService from "encrypted-cookie";
import axios from "axios";

const ProfileId = () => {
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState("JIOID");
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [errorContact, setErrorContact] = useState({}); // Error for each user
  const [loading, setLoading] = useState(false);
  const [showVal, setShowVal] = useState({});
  const [count, setCount] = useState("");
  const [mydetails, setMydetails] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!searchValue) {
      setError("Please enter a valid search value.");
      return;
    }

    setLoading(true);
    setError(null);

    const custom_headers = {
      "Content-Type": "application/json",
      Authorization: CookieService.getCookie("token"),
    };

    const searchParam =
      searchType === "JIOID" ? { id: searchValue } : { Name: searchValue };

    axios
      .get(`/api/users/GetUsersById`, {
        params: searchParam,
        headers: custom_headers,
      })
      .then((response) => {
        setData(response.data.users);
        console.log("Fetched users:", response.data.users);
      })
      .catch((error) => {
        setError("Error fetching users data. Please try again.");
        console.error("Error fetching users data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
  };

  const handleReveal = (userId) => {
    const personalDataString = localStorage.getItem("personal");
    const personalData = JSON.parse(personalDataString);
    setCount("");
    setMydetails(personalData);
    setErrorContact({});

    axios
      .get(`/api/users/CheckContactLimit`, {
        params: { id: personalData.JIOID },
      })
      .then((response) => {
        if (response.data.message === "Contact Revealed") {
          setCount(response.data.limitcount);
          setShowVal((prev) => ({ ...prev, [userId]: true }));
          // Clear error for the user when successfully revealed
          setErrorContact((prev) => ({ ...prev, [userId]: null }));
        }
      })
      .catch((error) => {
        // Set error only for the specific user
        setErrorContact((prev) => ({
          ...prev,
          [userId]: error.response?.data?.error || "An error occurred",
        }));
        console.error("Error fetching contact limit:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="bottom-section">
      <form onSubmit={handleSubmit}>
        <div className="radios">
          <label>
            <input
              type="radio"
              name="searchType"
              value="JIOID"
              checked={searchType === "JIOID"}
              onChange={handleSearchTypeChange}
            />
            Search by JIOID
          </label>
          <label>
            <input
              type="radio"
              name="searchType"
              value="Name"
              checked={searchType === "Name"}
              onChange={handleSearchTypeChange}
            />
            Search by Name
          </label>
        </div>
        <div className="searchSubmit">
          <input
            type="text"
            placeholder={`Enter ${searchType === "JIOID" ? "JIOID" : "Name"
              } here...`}
            value={searchValue}
            onChange={handleChange}
          />
          <button className="line-shade" type="submit" disabled={loading}>
            {loading ? "Loading..." : "Submit"}
          </button>
        </div>
      </form>

      {error && <p className="error-message">{error}</p>}

      <div className="profiles-container">
        {data.length > 0 &&
          data.map((each, index) => (
            <div key={index} className="profile-user">
              {each.images && each.images.length > 0 ? (
                <img
                  className="profile-img"
                  src={`data:image/jpeg;base64,${each.images[0]}`}
                  alt={`Profile image for ${each.Name}`}
                />
              ) : (
                <img src={require("../../images/OIP.jpeg")} alt="default" />
              )}
              <div className="profile-info">
                <h2>{each.Name}</h2>
                <p> <span style={{ fontWeight: "bold" }}> Age:</span> {each.Age}</p>
                <p> <span style={{ fontWeight: "bold" }}> Denomination:</span> {each.Denomination}</p>
                <p> <span style={{ fontWeight: "bold" }}> Bride or Groom: </span> {each["Bride/Bridegroom"]}</p>
                <p> <span style={{ fontWeight: "bold" }}> Cast:</span> {each.Cast}</p>
                <p> <span style={{ fontWeight: "bold" }}> Marital Status: </span>{each.MaritalStatus}</p>
                <p> <span style={{ fontWeight: "bold" }}> City:</span> {each.City}</p>
                <p> <span style={{ fontWeight: "bold" }}> Salary:</span> {each["Salary-PA"] || "Not-Specified"}</p>

                <div className="contactinfo">
                  {!showVal[each.JIOID] ? (
                    <>
                      <span
                        onClick={() => handleReveal(each.JIOID)}
                        className="line-shade"
                        style={{ width: "fit-content" }}
                      >
                        Reveal Contact
                      </span>
                      {/* Show error only for the user that is clicked */}
                      {errorContact[each.JIOID] && (
                        <p className="error-message">
                          {errorContact[each.JIOID]}
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <p style={{ fontWeight: "bold" }}>
                        You can view <span style={{ color: "red" }}>{count}</span> more contacts before reaching your
                        limit.
                      </p>

                      <p>Mobile Number: {each.Mobile}</p>
                      <p>Email: {each.email}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProfileId;
