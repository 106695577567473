import React, { useState, useEffect, useContext } from "react";
import "../../styles/Home.css";
import HowItWorks from "./HowItWorks.js";
import FeaturedProfiles from "./FeaturedProfiles.js";
import WhyAreWeBetter from "./WhyAreWeBetter.js";
import Testimonials from "./Testimonials.js";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Filteredlist from "../Dashboard/filteredlist.js";
import Filteration from "./filteration.js";
import CookieService from "encrypted-cookie";
import CircleLoader from "react-spinners/CircleLoader";
import { UserContext } from "../../context/UserContext.js"; // Adjust the path accordingly

const backenduRL = process.env.REACT_APP_API_UR;
function HomePage() {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const { errfil, setErrFil } = useContext(UserContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [Loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = CookieService.checkCookie("token");

    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const classnameforhomepage = isAuthenticated ? "height-Mantain" : "";
  return (
    <div className="Main-content">
      <div className={`${classnameforhomepage} homepage`}>
        {isAuthenticated ? <Filteration setLoading={setLoading} /> : ""}
      </div>
      {Loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircleLoader color="rgba(79, 191, 243, 1)" loading={Loading} />
          <p style={{ marginTop: "16px", color: "#333", fontSize: "16px" }}>
            Loading, please wait...
          </p>
        </div>
      ) : (
        ""
      )}

      {errfil ? (
        <p
          className="error-message"
          style={{ textAlign: "center", color: "red" }}
        >
          {errfil}
        </p>
      ) : (
        ""
      )}
      {userDetails.length > 0 ? (
        <p style={{ textAlign: "center" }}>
          There are{" "}
          <span style={{ color: "green", fontWeight: "bold" }}>
            {userDetails.length}
          </span>{" "}
          Profiles Match
        </p>
      ) : (
        ""
      )}
      {isAuthenticated ? <Filteredlist data={userDetails} /> : ""}

      <FeaturedProfiles />
      <HowItWorks />
      <WhyAreWeBetter />
      <Testimonials />
    </div>
  );
}

export default HomePage;
