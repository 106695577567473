// import React, { useEffect, useState } from "react";
// import { CiCirclePlus } from "react-icons/ci";
// import "../../styles/uploadimg.css";

// const AddUploadImg = () => {
//   const [images, setImages] = useState([]);
//   const [countimg, setCountImg] = useState(0);
//   useEffect(() => {
//     const per = localStorage.getItem("userimages");
//     const data = JSON.parse(per);
//     if (data) {
//       setImages(data);
//       setCountImg(data.length);
//     }
//   }, []);
//   return (
//     <div className="add-upload-section">
//       <div>
//         <h1>
//           You have added {countimg} photos. Add more to get better response.
//         </h1>
//         <div className="images-list">
//           {images
//             ? images.map((each) => (
//                 <img
//                   className="list-images"
//                   src={`data:image/jpeg;base64,${each}`}
//                   alt={`Profile ${each._id}`}
//                 />
//               ))
//             : ""}
//           <div className="add-img">
//             <CiCirclePlus className="plus-icon" />
//             <p>Add More Images</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddUploadImg;

import React, { useEffect, useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import "../../styles/uploadimg.css";

const AddUploadImg = () => {
  const [images, setImages] = useState([]);
  const [countimg, setCountImg] = useState(0);
  const [newImg, setNewImg] = useState([]);

  useEffect(() => {
    const per = localStorage.getItem("userimages");
    const data = JSON.parse(per);
    if (data) {
      setImages(data);
      setCountImg(data.length);
    }
  }, []);

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result.split(",")[1]); // Get base64 string
        };
      });
    });

    setNewImg(newImages);
  };

  const handleUpload = () => {
    console.log(newImg);
    // Make a POST request to your backend here
    fetch("YOUR_BACKEND_URL/upload", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ newImg }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        // Optionally handle success (e.g., show a message to the user)
      })
      .catch((error) => {
        console.error("Error:", error);
        // Optionally handle error (e.g., show an error message)
      });
  };

  return (
    <div className="add-upload-section">
      <div>
        <h1 className="upload-img">
          You have added {countimg} photos. Add more to get better response.
        </h1>
        {images.length > 0 ? (
          ""
        ) : (
          <p style={{ textAlign: "Center" }}>No images added yet.</p>
        )}
        <div className="images-list">
          {images.length > 0
            ? images.map((each, index) => (
                <img
                  key={index}
                  className="list-images"
                  src={`data:image/jpeg;base64,${each}`}
                  alt={`Profile ${index}`}
                />
              ))
            : ""}
          <div className="add-img">
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="file-upload"
            />
            <label htmlFor="file-upload" className="upload-label">
              <CiCirclePlus className="plus-icon" />
              <p>Add More Images</p>
            </label>
          </div>
        </div>
        <button onClick={handleUpload} className="upload-button">
          Upload Images
        </button>
      </div>
    </div>
  );
};

export default AddUploadImg;
