// import "../../styles/Navbar.css";
// import React, { useState, useEffect } from "react";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import { CgProfile } from "react-icons/cg";
// import CookieService from "encrypted-cookie";
// import { GiQueenCrown } from "react-icons/gi";
// import { FaXmark } from "react-icons/fa6";

// const Navbar = () => {
//   const [isNavExpanded, setIsNavExpanded] = useState(false);
//   const [img, setImg] = useState([]);
//   const [isNavScrolled, setIsNavScrolled] = useState(false);
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [showProfileContent, setShowProfileContent] = useState(false);
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     const per = localStorage.getItem("userimages");
//     const data = JSON.parse(per);
//     console.log(data)
//     if (data) {
//       setImg(data);
//     }
//     const token = CookieService.checkCookie("token");
//     setIsAuthenticated(!!token);
//   }, []); // Runs only on initial render

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 0) {
//         setIsNavScrolled(true);
//         setShowProfileContent(false);
//       } else {
//         setIsNavScrolled(false);
//       }
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, []);

//   useEffect(() => {
//     if (location.pathname === "/dashboard" && location.state?.scrollTo) {
//       const element = document.getElementById(location.state.scrollTo);
//       if (element) {
//         element.scrollIntoView({
//           behavior: "smooth",
//           block: "center",
//         });
//       }
//     }
//   }, [location]);

//   const handleScroll = (event, id) => {
//     event.preventDefault();

//     if (location.pathname !== "/dashboard") {
//       navigate("/dashboard", { state: { scrollTo: id } });
//     } else {
//       const element = document.getElementById(id);
//       if (element) {
//         element.scrollIntoView({
//           behavior: "smooth",
//           block: "center",
//         });
//       }
//     }

//     setIsNavExpanded(false);
//   };

//   const handleProfile = () => {
//     setShowProfileContent(!showProfileContent);
//   };

//   const handleLogout = () => {
//     CookieService.eraseCookie("token"); //erase set Cookie (key)
//     localStorage.removeItem("personal");
//     localStorage.removeItem("userimages");
//     CookieService.eraseCookie("SignInMethod");
//     setIsAuthenticated(false); // Update state immediately on logout
//     setShowProfileContent(false);
//     navigate("/signin");
//     setImg([])
//   };

//   const handleMyProfile = () => {
//     // CookieService.eraseCookie("token"); //erase set Cookie (key)
//     // localStorage.removeItem("personal");
//     // CookieService.eraseCookie("SignInMethod");
//     // setIsAuthenticated(false); // Update state immediately on logout
//     // setShowProfileContent(false);
//     navigate("/my-profile");
//   };

//   const handlePre = () => {
//     navigate("/subscribe-plan");
//   };

//   useEffect(() => {
//     const token = CookieService.checkCookie("token");
//     setIsAuthenticated(!!token);
//   }, [location]); // Re-run when location changes

//   return (
//     <nav className={`navigation ${isNavScrolled ? "scrolled" : ""}`}>
//       <a href="/dashboard" className="brand-name">
//         {/* <img src={require("../../images/rings.jpg")} alt="Brand" />
//         <h1 className="logo-head">JioMatrimony</h1> */}
//         <img src={require("../../images/JIOMLOGO.jpg")} />
//       </a>
//       <button
//         className="hamburger"
//         onClick={() => setIsNavExpanded(!isNavExpanded)}
//         title="Toggle Navigation"
//       >
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           className="h-5 w-5"
//           viewBox="0 0 20 20"
//           fill="white"
//         >
//           <path
//             fillRule="evenodd"
//             d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
//             clipRule="evenodd"
//           />
//         </svg>
//       </button>
//       <div
//         className={
//           isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
//         }
//       >
//         <ul>
//           <li>
//             <a
//               href="#Howitworks"
//               onClick={(e) => handleScroll(e, "Howitworks")}
//             >
//               How it works
//             </a>
//           </li>
//           <li>
//             <a href="#featured" onClick={(e) => handleScroll(e, "featured")}>
//               Profiles
//             </a>
//           </li>
//           <li>
//             <a href="#ourwork" onClick={(e) => handleScroll(e, "ourwork")}>
//               Our Work
//             </a>
//           </li>
//           <li>
//             <a
//               href="#matchmaking"
//               onClick={(e) => handleScroll(e, "matchmaking")}
//             >
//               Matchmaking
//             </a>
//           </li>
//           {isAuthenticated && isNavExpanded && (
//             <>
//               <li onClick={() => setIsNavExpanded(!isNavExpanded)}>
//                 <Link to="/subscribe-plan">Plans</Link>
//               </li>
//               <li onClick={() => setIsNavExpanded(!isNavExpanded)}>
//                 <Link onClick={handleLogout}>Logout</Link>
//               </li>
//             </>
//           )}
//           {isAuthenticated && !isNavExpanded && (
//             <>
//               <li>
//                 <button onClick={handlePre} className="pre-btn">
//                   <img src={require("../../images/crown.png")} />{" "}
//                   <span>Plans</span>
//                 </button>
//               </li>

//               {img.length>0 ? (
//                 <img
//                   className="img-back"
//                   style={{ width: "40px", cursor: "pointer" }}
//                   src={`data:image/jpeg;base64,${img[0]}`}
//                   alt={`Profile ${img._id} image 1`}
//                   onClick={handleProfile}
//                 />
//               ) : (
//                 <li>
//                   <CgProfile
//                     className="profile-pic"
//                     size={40}
//                     color="#007bff"
//                     onClick={handleProfile}
//                   />
//                 </li>
//               )}
//               {showProfileContent && (
//                 <div className="container">
//                   <FaXmark
//                     size={30}
//                     color="#fff"
//                     className="xmark"
//                     onClick={handleProfile}
//                   />
//                   {isAuthenticated && (
//                     <button onClick={handleMyProfile}>My Profile</button>
//                   )}
//                   <Link to="/profileid">
//                     <button>Search By ProfileId</button>
//                   </Link>

//                   <button onClick={handleLogout}>Logout</button>
//                 </div>
//               )}
//             </>
//           )}
//           {!isAuthenticated && isNavExpanded && (
//             <>
//               <li onClick={() => setIsNavExpanded(!isNavExpanded)}>
//                 <Link to="/signin">LOG IN</Link>
//               </li>
//               <li onClick={() => setIsNavExpanded(!isNavExpanded)}>
//                 <Link to="/signup">SIGN UP</Link>
//               </li>
//             </>
//           )}
//         </ul>
//       </div>

//       {!isAuthenticated && (
//         <div className="sign-buttons">
//           <Link to="/signin">
//             <button className="buttonnav">Signin</button>
//           </Link>
//           <Link to="/signup">
//             <button className="buttonnav">Signup</button>
//           </Link>
//         </div>
//       )}
//     </nav>
//   );
// };

// export default Navbar;

import "../../styles/Navbar.css";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import CookieService from "encrypted-cookie";
import { GiQueenCrown } from "react-icons/gi";
import { FaXmark } from "react-icons/fa6";

const Navbar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [img, setImg] = useState([]);
  const [isNavScrolled, setIsNavScrolled] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showProfileContent, setShowProfileContent] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Function to handle scrolling
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsNavScrolled(true);
        setShowProfileContent(false);
      } else {
        setIsNavScrolled(false);
      }
    };

    // Set initial state for token and images
    const initialize = () => {
      const per = localStorage.getItem("userimages");
      const data = JSON.parse(per);
      if (data) {
        setImg(data);
      }
      const token = CookieService.checkCookie("token");
      setIsAuthenticated(!!token);
    };

    // Call the initialization function
    initialize();

    // Check for location change and scroll if needed
    if (location.pathname === "/dashboard" && location.state?.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }

    // Add scroll event listener and remove it on cleanup
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [location]); // Runs when location changes

  const handleScroll = (event, id) => {
    event.preventDefault();

    if (location.pathname !== "/dashboard") {
      navigate("/dashboard", { state: { scrollTo: id } });
    } else {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }

    setIsNavExpanded(false);
  };

  const handleProfile = () => {
    setShowProfileContent(!showProfileContent);
  };

  const handleLogout = () => {
    CookieService.eraseCookie("token");
    localStorage.removeItem("personal");
    localStorage.removeItem("userimages");
    CookieService.eraseCookie("SignInMethod");
    setIsAuthenticated(false);
    setShowProfileContent(false);
    navigate("/signin");
    setImg([]);
  };

  const handleMyProfile = () => {
    navigate("/my-profile");
  };

  const handlePre = () => {
    navigate("/subscribe-plan");
  };

  return (
    <nav className={`navigation ${isNavScrolled ? "scrolled" : ""}`}>
      <a href="/dashboard" className="brand-name">
        {/* <img src={require("../../images/rings.jpg")} alt="Brand" />
        <h1 className="logo-head">JioMatrimony</h1> */}
        <img src={require("../../images/JIOMLOGO.jpg")} />
      </a>
      <button
        className="hamburger"
        onClick={() => setIsNavExpanded(!isNavExpanded)}
        title="Toggle Navigation"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
          <li>
            <a
              href="#Howitworks"
              onClick={(e) => handleScroll(e, "Howitworks")}
            >
              How it works
            </a>
          </li>
          <li>
            <a href="#featured" onClick={(e) => handleScroll(e, "featured")}>
              Profiles
            </a>
          </li>
          <li>
            <a href="#ourwork" onClick={(e) => handleScroll(e, "ourwork")}>
              Our Work
            </a>
          </li>
          <li>
            <a
              href="#matchmaking"
              onClick={(e) => handleScroll(e, "matchmaking")}
            >
              Matchmaking
            </a>
          </li>
          {isAuthenticated && isNavExpanded && (
            <>
              <li onClick={() => setIsNavExpanded(!isNavExpanded)}>
                <Link to="/subscribe-plan">Plans</Link>
              </li>
              <li onClick={() => setIsNavExpanded(!isNavExpanded)}>
                <Link onClick={handleLogout}>Logout</Link>
              </li>
            </>
          )}
          {isAuthenticated && !isNavExpanded && (
            <>
              <li>
                <button onClick={handlePre} className="pre-btn">
                  <img src={require("../../images/crown.png")} />{" "}
                  <span>Plans</span>
                </button>
              </li>

              {img.length > 0 ? (
                <img
                  className="img-back"
                  style={{ width: "45px", cursor: "pointer", height: "50px" }}
                  src={`data:image/jpeg;base64,${img[0]}`}
                  alt={`Profile ${img._id} image 1`}
                  onClick={handleProfile}
                />
              ) : (
                <li>
                  <CgProfile
                    className="profile-pic"
                    size={40}
                    color="#007bff"
                    onClick={handleProfile}
                  />
                </li>
              )}
              {showProfileContent && (
                <div className="container">
                  <FaXmark
                    size={30}
                    color="#fff"
                    className="xmark"
                    onClick={handleProfile}
                  />
                  {isAuthenticated && (
                    <button onClick={handleMyProfile}>My Profile</button>
                  )}
                  <Link to="/profileid">
                    <button>Search By ProfileId</button>
                  </Link>

                  <button onClick={handleLogout}>Logout</button>
                </div>
              )}
            </>
          )}
          {!isAuthenticated && isNavExpanded && (
            <>
              <li onClick={() => setIsNavExpanded(!isNavExpanded)}>
                <Link to="/signin">LOG IN</Link>
              </li>
              <li onClick={() => setIsNavExpanded(!isNavExpanded)}>
                <Link to="/signup">SIGN UP</Link>
              </li>
            </>
          )}
        </ul>
      </div>

      {!isAuthenticated && (
        <div className="sign-buttons">
          <Link to="/signin">
            <button className="buttonnav">Sign In</button>
          </Link>
          <Link to="/signup">
            <button className="buttonnav">Sign Up</button>
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
