import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/profilecreating.css"; // Import the CSS file
import { useNavigate } from "react-router";
import CookieService from "encrypted-cookie";
import { toast } from "react-toastify";

import { Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Slider from "react-slick";
import { Grid, Box } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { CiLock } from "react-icons/ci";
import { GiInfo } from "react-icons/gi";
import { MdComputer } from "react-icons/md";
import { Link } from "react-router-dom";

const CreateProfile = (props) => {
  const [formData, setFormData] = useState({
    Name: "",
    Age: "",
    height: "",
    weight: "",
    Education: "",
    ["Salary-PA_Standardized"]: "",
    Country: "",
    State: "",
    City: "",
    gender: "",
    MaritalStatus: "",
    MotherTongue: "",
    livingWithFamily: "",
    Denomination: "",
    religion: "",
    Cast: "",
    lookingFor: "",
    Mobile: "",
    otp: "",
    image: [],
    AboutMe: "",
    Prefering: "",
    AboutFamily: "", // State for OTP input
  });
  const [proimage, setimage] = useState([]);
  const [profiledataID, setprofiledataID] = useState("");
  const [showOtpSection, setShowOtpSection] = useState(false); // State for OTP section visibility
  const [otpSent, setOtpSent] = useState(false); // State to track if OTP has been sent
  const [otpVerified, setOtpVerified] = useState(false); // State to track if OTP is verified

  const [salaryOptions, setsalaryOptions] = useState([
    "Below 2LPA",
    "2LPA - 5LPA",
    "5LPA - 10LPA",
    "Above 10LPA",
  ]);

  const [community, setCommunity] = useState([]);
  const [salary, setSalary] = useState([]);
  const [caste, setCaste] = useState([]);
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [images, setImages] = useState(null);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const fileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };

    Promise.all(files.map(fileToBase64))
      .then((base64Images) => {
        setSelectedFiles(base64Images);
      })
      .catch((error) => {
        console.error("Error converting files to Base64:", error);
      });
  };
  // Function to generate height options
  const generateHeightOptions = () => {
    const heights = [];
    for (let feet = 4; feet <= 7; feet++) {
      for (let inches = 0; inches < 12; inches += 1) {
        let height = `${feet}ft ${inches}inch`;
        if (inches === 0) {
          height = `${feet}ft`;
        }
        heights.push(height);
      }
    }
    return heights;
  };

  useEffect(() => {
    if (formData.gender === "Male") {
      setFormData((prevData) => ({
        ...prevData,
        lookingFor: "Bride",
      }));
    } else if (formData.gender === "Female") {
      setFormData((prevData) => ({
        ...prevData,
        lookingFor: "Bridegroom",
      }));
    } else {
      // Clear lookingFor if no gender is selected
      setFormData((prevData) => ({
        ...prevData,
        lookingFor: "",
      }));
    }
  }, [formData.gender]);

  useEffect(() => {
    Dropdown();
    if (props.view) {
      console.log("ooo");
      currentUser();
      // const per = localStorage.getItem("userimages");
      // const data = JSON.parse(per);
      // if (data) {
      //   setImages(data);
      // }
    }
  }, [props.view]);

  const currentUser = async () => {
    const per = localStorage.getItem("personal");
    const data = JSON.parse(per);

    // setFormData({
    //   Name: data.Name,

    //   Age: data.Age,
    //   height: data.height,
    //   weight: data.weight,
    //   Education: data.Education,
    //   salary: data.salary,
    //   Country: data.Country,
    //   State: data.State,
    //   City: data.City,
    //   gender: data.gender,
    //   MaritalStatus: data.MaritalStatus,
    //   MotherTongue: data.MotherTongue,
    //   livingWithFamily: data.livingWithFamily,
    //   Denomination: data.Denomination,
    //   religion: data.religion,
    //   Cast: data.Cast,
    //   lookingFor: data.lookingFor,
    //   Mobile: data.Mobile,
    //   AboutMe: data.AboutMe,
    //   Prefering: data.Prefering,
    // });
    var custom_headers = {
      "Content-Type": "application/json",
      Authorization: CookieService.getCookie("token"),
    };
    axios
      .get(`/api/users/getPersonalDetails`, {
        params: { email: data.email },
        headers: custom_headers,
      })
      .then((response) => {
        const data = response.data;
        console.log(response, data);
        setFormData(data.users[0]);
        setImages(data.images);
        localStorage.setItem("userimages", JSON.stringify(data.images));
      })
      .catch((error) => {
        console.error("Error fetching users data:", error);
      });
  };
  console.log(formData, "fromData");
  const Dropdown = async () => {
    try {
      const response = await fetch(`/api/admin/searchable-dropdown`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (response.ok) {
        var communitytmp = [];
        var salarytmp = [];
        var castetmp = [];
        for (var x in result.data.salaryData) {
          salarytmp.push(result.data.salaryData[x].name);
        }
        for (var x in result.data.communityData) {
          communitytmp.push(result.data.communityData[x].name);
        }
        for (var x in result.data.casteData) {
          castetmp.push(result.data.casteData[x].name);
        }
        setCommunity(communitytmp);
        setSalary(salarytmp);
        setCaste(castetmp);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    const { name, files, value } = e.target;

    if (name === "image") {
      const fileArray = Array.from(files);
      const imgPromises = fileArray.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onload = () => {
            resolve(reader.result);
          };

          reader.onerror = (error) => {
            reject(error);
          };
        });
      });

      Promise.all(imgPromises)
        .then((imgarr) => {
          // imgarr is an array of base64 encoded strings
          setFormData((prevData) => ({
            ...prevData,
            [name]: imgarr, // Store the array of base64 strings in state
          }));
        })
        .catch((error) => {
          console.error("Error reading files:", error);
        });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSave = (formData) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      id: profiledataID,
      image: selectedFiles,
      data: formData,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("/api/users/upme", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

  const handleSendOtp = async () => {
    var custom_headers = {
      "Content-Type": "application/json",
      Authorization: CookieService.getCookie("token"),
    };
    try {
      const response = await axios.post(
        `/api/auth/sendOtpVerification`,
        {
          value: formData.Mobile,
        },
        {
          headers: custom_headers,
        }
      );
      if (response.data.success) {
        setOtpSent(true);
        setShowOtpSection(true);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      // Handle error (e.g., show an error message)
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(`/api/auth/check-otp`, {
        Mobile: formData.Mobile,
        otp: formData.otp,
      });
      console.log(response);
      if (response.data.success) {
        setOtpVerified(true);
        setShowOtpSection(false);
        setOtpSent(true);
        console.log("OTP verified successfully.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      // Handle error (e.g., show an error message)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSend = { ...formData };

    // Remove fields if they are empty
    if (!dataToSend.Mobile) {
      delete dataToSend.Mobile;
    }
    if (!dataToSend.otp) {
      delete dataToSend.otp;
    }
    console.log(dataToSend);
    const custom_headers = {
      "Content-Type": "application/json",
      Authorization: CookieService.getCookie("token"),
    };

    try {
      const response = await axios.post(
        `/api/profile/profiledetails`,
        dataToSend,
        {
          headers: custom_headers,
        }
      );
      if (response.data.message) {
        setFormData({
          Name: "",
          Age: "",
          height: "",
          weight: "",
          Education: "",
          ["Salary-PA_Standardized"]: "",
          Country: "",
          State: "",
          City: "",
          gender: "",
          MaritalStatus: "",
          MotherTongue: "",
          livingWithFamily: "",
          Denomination: "",
          religion: "",
          Cast: "",
          lookingFor: "",
          Mobile: "",
          otp: "",
          AboutMe: "",
          Prefering: "",
          AboutFamily: "",
        });

        localStorage.setItem("personal", JSON.stringify(response.data.details));
        {
          props.view
            ? toast.success("Profile Updated successfully!")
            : toast.success("Profile created successfully!");
        }

        setTimeout(() => {
          navigate("/subscribe-plan"); // Navigate to the success page or any other route
        }, 2000);
      }
    } catch (error) {
      console.error("Error creating profile:", error);
      // Handle error (e.g., show an error message)
    }
  };

  const method = CookieService.getCookie("SignInMethod");

  return (
    <div className="profile-create-bg">
      <div className="profile-container">
        {props.view ? (
          <h2 className="profile-heading">My Profile</h2>
        ) : (
          <h2 className="profile-heading">Register</h2>
        )}

        <div className="uploadpic">
          <div className="profile-upload">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {images && images.length > 0 ? (
                <>
                  <img
                    className="img-back"
                    src={`data:image/jpeg;base64,${images[0]}`}
                    alt={`Profile ${images._id} image 1`}
                  />
                </>
              ) : (
                <img src={require("../../images/OIP.jpeg")} />
              )}

              {props.view ? (
                <>
                  <p className="jioid-profile"> JIO_ID: {formData.JIOID}</p>
                  <Link
                    to="/Add_Edit_Photos"
                    style={{ textDecoration: "none" }}
                  >
                    <p style={{ textAlign: "center" }} className="add-edit">
                      Add/Edit Photos
                    </p>
                  </Link>
                </>
              ) : (
                ""
              )}
            </div>

            {/* <p>
            <CiLock />
            100% Privacy controls availiable
            <GiInfo />
          </p> */}
            {props.view ? (
              // <button>
              //   <input
              //     type="file"
              //     accept="image/*"
              //     multiple
              //     name="image"
              //     onChange={handleChange}
              //   />
              //   <MdComputer className="imagegiv" />
              //   Update Profile Pic
              // </button>
              // <label className="custom-file-upload">
              //   <input
              //     type="file"
              //     accept="image/*"
              //     multiple
              //     name="image"
              //     onChange={handleChange}
              //   />
              //   <MdComputer className="imagegiv" />
              //   Update Profile Pic
              // </label>
              ""
            ) : (
              // <button>
              //   <input
              //     type="file"
              //     accept="image/*"
              //     multiple
              //     name="image"
              //     onChange={handleChange}
              //   />
              //   <MdComputer className="imagegiv" />
              //   Upload Profile Pic
              // </button>
              <label className="custom-file-upload">
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  name="image"
                  onChange={handleChange}
                />
                <MdComputer className="imagegiv" />
                Upload Profile Pic
              </label>
            )}
          </div>
        </div>

        <form className="profile-form" onSubmit={handleSubmit}>
          {method === "google" && (
            <>
              <div>
                <label htmlFor="number" className="profile-form-label">
                  Mobile Number
                </label>
                {otpVerified ? <i className="fas fa-check"></i> : ""}

                <input
                  type="tel"
                  id="number"
                  name="Mobile"
                  value={formData.Mobile}
                  onChange={handleChange}
                  className="profile-form-input"
                  required
                />

                {!otpSent && !props.view && (
                  <button
                    type="button"
                    onClick={handleSendOtp}
                    className="profile-submit-button"
                  >
                    Send OTP
                  </button>
                )}
              </div>

              {showOtpSection && (
                <div>
                  <label htmlFor="otp" className="profile-form-label">
                    OTP
                  </label>
                  <input
                    type="text"
                    id="otp"
                    name="otp"
                    value={formData.otp}
                    onChange={handleChange}
                    className="profile-form-input"
                    required
                  />
                  <button
                    type="button"
                    onClick={handleVerifyOtp}
                    className="profile-submit-button"
                  >
                    Verify OTP
                  </button>
                </div>
              )}
            </>
          )}
          {/* first name*/}
          <div>
            <label htmlFor="Firstname" className="profile-form-label">
              Name
            </label>
            <input
              type="text"
              id="Firstname"
              name="Name"
              value={formData.Name}
              onChange={handleChange}
              className="profile-form-input"
              required
            />
          </div>

          {/* Age Input */}
          <div>
            <label htmlFor="age" className="profile-form-label">
              Age
            </label>
            <input
              type="number"
              id="age"
              name="Age"
              value={formData.Age}
              onChange={handleChange}
              className="profile-form-input"
              required
            />
          </div>
          {/* Height Dropdown */}
          <div>
            <label htmlFor="height" className="profile-form-label">
              Height
            </label>
            <select
              id="height"
              name="height"
              value={formData.height}
              onChange={handleChange}
              className="profile-form-select"
              required
            >
              <option value="" disabled>
                Select one
              </option>
              {generateHeightOptions().map((height, index) => (
                <option key={index} value={height}>
                  {height}
                </option>
              ))}
            </select>
          </div>
          {/* Weight Dropdown */}
          <div>
            <label htmlFor="weight" className="profile-form-label">
              Weight
            </label>
            <input
              type="number"
              id="weight"
              name="weight"
              value={formData.weight}
              onChange={handleChange}
              className="profile-form-input"
              required
            />
          </div>
          {/* Educational Details Dropdown */}

          {props.view ? (
            <div>
              <label
                htmlFor="educationalDetails"
                className="profile-form-label"
              >
                Educational Details
              </label>
              <input
                id="educationalDetails"
                name="Education"
                value={formData.Education}
                className="profile-form-select"
                required
              />
            </div>
          ) : (
            <div>
              <label
                htmlFor="educationalDetails"
                className="profile-form-label"
              >
                Educational Details
              </label>
              <select
                id="educationalDetails"
                name="Education"
                value={formData.Education}
                onChange={handleChange}
                className="profile-form-select"
                required
              >
                <option value="" disabled>
                  Select one
                </option>
                <option value="High-school">High School</option>
                <option value="Bachelors">Bachelor's Degree</option>
                <option value="Master">Master's Degree</option>
                <option value="PHD">PhD</option>
              </select>
            </div>
          )}
          {/* Salary Input */}

          {props.view ? (
            <div>
              <label htmlFor="Salary-PA_Standardized">Salary</label>
              <input
                id="salary"
                name="Salary-PA_Standardized"
                type="text"
                value={formData["Salary-PA_Standardized"]}
                className="profile-form-input"
              />
            </div>
          ) : (
            <div>
              <label htmlFor="Salary-PA_Standardized">Salary</label>
              <select
                id="salary"
                name="Salary-PA_Standardized"
                onChange={handleChange}
                value={formData["Salary-PA_Standardized"]}
                className="profile-form-input"
              >
                <option>Select</option>
                {salaryOptions.map((salary, index) => (
                  <option key={index} value={salary}>
                    {salary}
                  </option>
                ))}
              </select>
            </div>
          )}
          {/* Country Input */}
          <div>
            <label htmlFor="country" className="profile-form-label">
              Country
            </label>
            <input
              type="text"
              id="country"
              name="Country"
              value={formData.Country}
              onChange={handleChange}
              className="profile-form-input"
              required
            />
          </div>
          {/* State Input */}
          <div>
            <label htmlFor="state" className="profile-form-label">
              State
            </label>
            <input
              type="text"
              id="state"
              name="State"
              value={formData.State}
              onChange={handleChange}
              className="profile-form-input"
              required
            />
          </div>
          {/* City Input */}
          <div>
            <label htmlFor="city" className="profile-form-label">
              City
            </label>
            <input
              type="text"
              id="city"
              name="City"
              value={formData.City}
              onChange={handleChange}
              className="profile-form-input"
              required
            />
          </div>
          {/* Gender Dropdown */}
          <div>
            <label htmlFor="Gender" className="profile-form-label">
              Gender
            </label>
            <select
              id="Gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className="profile-form-select"
              required
            >
              <option value="" disabled>
                Select one
              </option>
              <option value="Female">Female</option>
              <option value="Male">Male</option>
            </select>
          </div>
          {/* Marital Status Dropdown */}
          <div>
            <label htmlFor="maritalStatus" className="profile-form-label">
              Marital Status
            </label>
            <select
              id="maritalStatus"
              name="MaritalStatus"
              value={formData.MaritalStatus}
              onChange={handleChange}
              className="profile-form-select"
              required
            >
              <option value="" disabled>
                Select one
              </option>
              <option value="never-married">UnMarried</option>
              <option value="divorced">Divorced</option>
              <option value="divorced-with-children">
                Divorced With Children
              </option>
              <option value="widowed">Widowed</option>
              <option value="widowed-with-children">
                Widowed With Children
              </option>
            </select>
          </div>
          {/* Mother Tongue Input */}
          <div>
            <label htmlFor="motherTongue" className="profile-form-label">
              Mother Tongue
            </label>
            <input
              type="text"
              id="motherTongue"
              name="MotherTongue"
              value={formData.MotherTongue}
              onChange={handleChange}
              className="profile-form-input"
              required
            />
          </div>
          {/* Living with Family Dropdown */}
          <div>
            <label htmlFor="livingWithFamily" className="profile-form-label">
              Living with your family
            </label>
            <select
              id="livingWithFamily"
              name="livingWithFamily"
              value={formData.livingWithFamily}
              onChange={handleChange}
              className="profile-form-select"
              required
            >
              <option value="" disabled>
                Select one
              </option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>

          {/* religion Input */}
          <div>
            <label htmlFor="religion" className="profile-form-label">
              Religion
            </label>
            <input
              type="text"
              id="religion"
              name="religion"
              value={formData.religion}
              onChange={handleChange}
              className="profile-form-input"
              required
            />
          </div>

          {/* Cast Input */}
          <div>
            <label htmlFor="cast" className="profile-form-label">
              Cast
            </label>{" "}
            <input
              type="text"
              id="cast"
              name="Cast"
              value={formData.Cast}
              onChange={handleChange}
              className="profile-form-input"
              required
            />
          </div>
          {/* denomination Input */}
          <div>
            <label htmlFor="denomination" className="profile-form-label">
              Denomination/Subcaste
            </label>{" "}
            <input
              type="text"
              id="denomination"
              name="Denomination"
              value={formData.Denomination}
              onChange={handleChange}
              className="profile-form-input"
              required
            />
          </div>
          {/* Looking For Dropdown */}
          <div>
            <label htmlFor="lookingFor" className="profile-form-label">
              Whom you are looking for
            </label>
            <select
              id="lookingFor"
              name="lookingFor"
              value={formData.lookingFor}
              onChange={handleChange}
              className="profile-form-select"
              required
              disabled={formData.gender && formData.lookingFor !== ""}
            >
              <option value="" disabled>
                Select one
              </option>
              <option value="Bride">Bride</option>
              <option value="Bridegroom">Bridegroom</option>
            </select>
          </div>

          {/* About family */}
          <div>
            <label htmlFor="Aboutfamily" className="profile-form-label">
              About Family
            </label>
            <textarea
              name="AboutFamily"
              id="Aboutfamily"
              placeholder="Tell us about your family..."
              rows="5"
              cols="50"
              maxlength="500"
              onChange={handleChange}
              className="profile-form-input"
              value={formData.AboutFamily}
            ></textarea>
          </div>
          {/* About You */}
          <div>
            <label htmlFor="lookingFor" className="profile-form-label">
              About You
            </label>
            <textarea
              name="AboutMe"
              placeholder="Tell us about yourself..."
              rows="5"
              cols="50"
              maxlength="500"
              onChange={handleChange}
              className="profile-form-input"
              value={formData.AboutMe}
            ></textarea>
          </div>

          <div>
            <label htmlFor="lookingFor" className="profile-form-label">
              Prefrences
            </label>
            <textarea
              name="Prefering"
              placeholder="Tell Your preferences that you want in your partner..."
              rows="5"
              cols="50"
              maxlength="500"
              onChange={handleChange}
              className="profile-form-input"
              value={formData.Prefering}
            ></textarea>
          </div>
          {props.view ? (
            <div>
              <p>
                You have viewed {formData.contactShowLimit} contacts. You can
                still view{" "}
                {formData.membership_status === "Paid"
                  ? 50 - formData.contactShowLimit
                  : formData.membership_status === "Unpaid"
                  ? 10 - formData.contactShowLimit
                  : formData.membership_status === "Expired"
                  ? 5 - formData.contactShowLimit
                  : 0}{" "}
                more contacts before reaching your limit.
              </p>
            </div>
          ) : (
            ""
          )}
          {!props.view ? (
            <button type="submit" className="profile-submit-button">
              Create Profile
            </button>
          ) : (
            <button type="submit" className="profile-submit-button">
              Update Profile
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default CreateProfile;
